import React from 'react';

// import { render } from 'react-dom';

// import { withScriptjs } from "react-google-maps";
// import Map from './components/MapDirection';

import Rooter from './components/util/Rooter';

// import Firebase from './components/Firebase';
// import FirebasePhone from './components/FirebasePhone';
// import Loader from './components/Loader'
// import Slide from './components/Slide';
// import Niveau from './components/Niveau';
// import Lock from './components/Lock';
// import BrandsSection from './components/BrandsSection';

// import logo from './logo.svg';
// import CountDown from './components/enigmes/Count';
// import Enigme1 from './components/enigmes/Enigme1';
// import Enigme2 from './components/enigmes/Enigme2';
// import Test from './components/test';
// import Draw from './components/Draw';
// import DeviceDetect from './components/DeviceDetect';
// import MapDirection from './components/MapDirection';
// import RooterTest from './components/RooterTest';
// import Niveau from './components/Niveau';
// import ProgressBar from './components/ProgressBar'


// import TestIndex from './components/test/test-index'

// const MapLoader = withScriptjs(Map);
// function App() {
export default class App extends React.Component{
  

  render(){
    return (
      <section>
        {/* <Firebase/> */}
        {/* <FirebasePhone/> */}
        {/* <Loader/> */}
        {/* <Slide/> */}
        {/* <Niveau/> */}
        {/* <Lock/> */}
        {/* <BrandsSection/> */}


        {/* <TestIndex/> */}


        {/* <CountDown/> */}
        {/* <Enigme1/> */}
        <Rooter/>

        {/* <Niveau/> */}
        {/* <Enigme2/> */}
        {/* <Test/> */}
        {/* <Draw/> */}
        {/* <DeviceDetect/> */}
        {/* <RooterTest/> */}
        


        {/* Map */}
      {/* <Map/> */}
      {/* <MapLoader */}
        {/* googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDmVf0AAsOZKBirdmAb67M_U4k8atiU-d4" */}
        {/* loadingElement={<div style={{ height: `102%` }} />} */}
      {/* />   */}

      </section>
    );
  }
}